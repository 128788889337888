<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'profile-points-partner',
  components: {
    BaseIcon,
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      return this.partner.type
    },
    icon() {
      return { url: 'menu/points/velocity' }
    },
    name() {
      const { firstName, lastName, name } = this.partner

      return name || `${firstName} ${lastName}`
    },
  },
  methods: {
    onClick() {
      if (!this.isDisabled) {
        this.$emit('click')
      }
    },
  },
}
</script>

<template>
  <div
    class="flex w-full items-center justify-between"
    :class="[isDisabled && 'cursor-not-allowed opacity-50']"
  >
    <div class="flex h-12 w-12 items-center justify-center rounded-full">
      <base-icon :svg="icon.url" size="2xl" />
    </div>

    <div class="flex-1 overflow-hidden pl-[15px]" @click="onClick">
      <div class="truncate font-bold">
        {{ name }}
      </div>
      <div class="truncate text-sm text-eonx-neutral-600">
        {{ partner.accountNumber }}
      </div>
    </div>

    <div v-if="$slots.action" class="ml-5 shrink-0">
      <slot name="action" />
    </div>
  </div>
</template>
